/*
 * @Author: linghongxiang linghongxiang@kaike.la
 * @Date: 2023-02-07 17:24:36
 * @LastEditors: linghongxiang linghongxiang@kaike.la
 * @LastEditTime: 2023-02-07 17:28:05
 * @FilePath: \kkl-online-school\src\common\mixins\title.js
 * @Description:
 */
import TitleMap from '@/common/enums/titleMap';

export default {
  data() {
    const { hostname } = window.location;
    return {
      title: TitleMap[hostname] || TitleMap.wlxiao,
    };
  },
  created() {
    this.setTitle();
  },
  methods: {
    setTitle() {
      document.title = this.title;
    },
  },
};
