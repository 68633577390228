/*
 * @Author: linghongxiang linghongxiang@kaike.la
 * @Date: 2023-02-07 15:47:15
 * @LastEditors: linghongxiang linghongxiang@kaike.la
 * @LastEditTime: 2023-03-06 16:56:41
 * @FilePath: \kkl-online-school\src\common\enums\faviconMap.js
 * @Description: favicon 配置
 */
const yucai =
  'https://festatic.estudy.cn/assets/kkl-online-school/public/yunyucai.ico';
const wlxiao =
  'https://festatic.estudy.cn/assets/kkl-online-school/public/onlineSchool.ico';

export default {
  'yunyucai.cn': yucai,
  'www.yunyucai.cn': yucai,
  'yyc.wlxiao.com': yucai,
  wlxiao, // 默认配置
};
