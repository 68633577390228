export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';
export const SET_QUESTION_LIBRARY_VERSION = 'SET_QUESTION_LIBRARY_VERSION';
export const SET_APP_ATTR = 'SET_APP_ATTR'; // 设置app
import icpMap from '@/common/enums/icpMap';
import { getIsYucai } from '@/common/scripts/utils/host';

const mainDomain = location.hostname.match(/[^.]*(.com)?(.cn)?$/)[0];
const getBeianInfo = () => {
  return (icpMap[mainDomain] || {}).icpId;
};
const getCopyright = () => {
  return (icpMap[mainDomain] || {}).copyright;
};
const isYucai = getIsYucai();

const state = {
  loading: false,
  isLoadedPremission: false, // 是否加载完成权限数据
  questionLibVersion: 1,
  isYucai,
  beian: getBeianInfo(),
  copyright: getCopyright(),
};

const mutations = {
  [SET_LOADING_STATUS](store, payload) {
    store.loading = payload;
  },
  [SET_QUESTION_LIBRARY_VERSION](store, payload) {
    store.questionLibVersion = payload;
  },
  [SET_APP_ATTR](store, { key, value }) {
    store[key] = value;
    console.log(store, key, value);
  },
};

export default {
  name: 'app',
  namespaced: true,
  state,
  mutations,
};
