/*
 * @Author: linghongxiang linghongxiang@kaike.la
 * @Date: 2023-02-07 15:33:23
 * @LastEditors: linghongxiang linghongxiang@kaike.la
 * @LastEditTime: 2023-02-07 16:26:47
 * @FilePath: \kkl-online-school\src\common\scripts\utils\host.js
 * @Description: host 相关
 */

const { hostname } = location;

export function getIsYucai() {
  const regex = /^yyc\.(.*?)\.(.*?)$/;
  const regex2 = /yunyucai\.cn/;
  const isYucai = regex.test(hostname) || regex2.test(hostname);
  return isYucai;
}
