/*
 * @Author: linghongxiang linghongxiang@kaike.la
 * @Date: 2023-03-06 16:53:59
 * @LastEditors: linghongxiang linghongxiang@kaike.la
 * @LastEditTime: 2023-03-06 16:55:37
 * @FilePath: \kkl-online-school\src\common\enums\titleMap.js
 * @Description: 网站标题配置
 */
const yucai = '育才云朵朵';
const wlxiao = '未来校';

export default {
  'yunyucai.cn': yucai,
  'www.yunyucai.cn': yucai,
  'yyc.wlxiao.com': yucai,
  wlxiao, // 默认配置
};
