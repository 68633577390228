import dayjs from 'dayjs';

/**
 * date format
 * @param {*} date   Date 对象
 * @param {*} str 时间格式字符串
 */
const dateFormat = (date, str = 'yyyy-MM-dd') => {
  if (typeof date === 'string') {
    date = toDate(date);
  }

  const o = {
    'M+': date.getMonth() + 1, // month
    'd+': date.getDate(), // day
    'h+': date.getHours(), // hour
    'm+': date.getMinutes(), // minute
    's+': date.getSeconds(), // second
    'q+': Math.floor((date.getMonth() + 3) / 3), // quarter
    S: date.getMilliseconds(), // millisecond
  };

  let format = str;
  if (/(y+)/.test(format)) {
    format = format.replace(
      RegExp.$1,
      `${date.getFullYear()}`.substr(4 - RegExp.$1.length),
    );
  }

  Object.keys(o).forEach((k) => {
    if (new RegExp(`(${k})`).test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length),
      );
    }
  });

  return format;
};

/**
 * 是否为今天或明天
 * @param {*} timestamp  需要判断的时间戳
 * @param {*} currentTimestamp 当前的时间戳
 */

const isTodayOrTomorrow = (timestamp = Date.now(), currentTimestamp) => {
  const nowTimestamp = currentTimestamp || Date.now();
  const todayStartTimestamp = new Date(
    dateFormat(new Date(nowTimestamp), 'yyyy/MM/dd'),
  ).getTime();
  const tomorrowEndTimestamp = todayStartTimestamp + 2 * 24 * 3600 * 1000;
  return timestamp >= todayStartTimestamp && timestamp < tomorrowEndTimestamp;
};

/**
 * 格式化日期
 * @param {*} timestamp 毫秒时间戳
 * @param {*} fn 调用格式化函数
 * @param {*} locale 是否显示时分秒
 */
function localeTimeFormat(timestamp, fn = 'cn', locale = 'date') {
  /**
   * fn参数为date, 只转换日期YYYYMMDD
   *
   * @returns {String}
   */
  function onlyDateFormat() {
    const currentTimestamp = new Date(timestamp);
    return dayjs(currentTimestamp).format('YYYYMMDD');
  }
  /**
   * fn参数为cn, 按以下规则显示
   * 若是今天或者昨天按以下locale规则显示、否则就显示YYYY-MM-DD
   * locale 参数为date，只返回日期，如：20180815; 参数为time，返回今天/昨天/明天 时:分 或 年-月-日 时:分
   *
   * @param {*} timestamp 毫秒时间戳
   * @param {*} locale 控制是否显示时分秒
   * @returns {String}
   */
  function cnDateFormat(_timestamp, _locale) {
    // 时间格式映射
    const localeMap = { date: 'YYYY-MM-DD', time: 'HH:mm' };
    // 传入时间戳格式化
    const currentTimestamp = new Date(_timestamp);
    const currentDate = dayjs(currentTimestamp).format(localeMap.date);
    const currentTime = dayjs(currentTimestamp).format(localeMap.time);
    // 指定时间点格式化
    const date = new Date();
    const today = dayjs(date).format(localeMap.date);
    const yesterday = dayjs(date).add(-1, 'days').format(localeMap.date);
    const tomorrow = dayjs(date).add(1, 'days').format(localeMap.date);
    // 是否显示时分秒字段控制
    const extend = _locale.match('time') ? currentTime : '';
    // 返回格式化日期
    switch (currentDate) {
      case today:
        return `今天 ${extend}`;
      case yesterday:
        return `昨天 ${extend}`;
      case tomorrow:
        return `明天 ${extend}`;
      default:
        return `${currentDate} ${extend}`;
    }
  }
  /**
   * fn参数调用方法
   */
  switch (fn) {
    case 'cn':
      return cnDateFormat(timestamp, locale);
    case 'date':
      return onlyDateFormat(timestamp);
    default:
      return null;
  }
}

/**
 * 把 yyyy-mm-dd hh:mm:ss
 * yyyy-mm-dd
 * yyyy/mm/dd
 * 转成 Date 格式
 * @param {String} str 日期字符
 * @returns {Date | null} 时间
 */
const toDate = (str) => {
  return str ? new Date(Date.parse(str.replace(/\-/g, '/'))) : null;
};

/**
 * Parse second to time string
 *
 * @param {Number} second
 * @return {String} 00:00 or 00:00:00
 */
function secondToTime(second) {
  second = second || 0;
  if (second === 0 || second === Infinity || second.toString() === 'NaN') {
    return '00:00';
  }
  const add0 = (num) => num.toString().padStart(2, '0');
  const hour = Math.floor(second / 3600);
  const min = Math.floor((second - hour * 3600) / 60);
  const sec = Math.floor(second % 60);
  return (hour > 0 ? [hour, min, sec] : [min, sec]).map(add0).join(':');
}

/**
 * [日期相关函数方法]
 */
// 计算记忆  减少重复计算   毫秒
// ps:   dateFns.year,dateFns.day
function dateFn() {
  this.second = 1000;
  this.minute = this.second * 60;
  this.hour = this.minute * 60;
  this.day = this.hour * 24;
  this.year = this.day * 365;
}
// 获取  '01' 格式的日期
// ps:   dateFns.doubleDigit(8)=>'08'
dateFn.prototype.doubleDigit = function (n) {
  const r = parseInt(n);
  if (r > 9) {
    return r;
  } else {
    return `0${r}`;
  }
};
// 日期转换
// ps:   dateFns.changeTime(n,'/')=> 2019/09/03 23:59:59
// ps:   dateFns.changeTime(n,null,true)=> 2019-09-03
dateFn.prototype.changeTime = function (t, divider, nohour) {
  const d = divider || '-'; // 默认分隔符
  if (t) {
    const date = new Date(t);
    const Y = date.getFullYear() + (d === 'cn' ? '年' : d);
    const M =
      dateFns.doubleDigit(date.getMonth() + 1) + (d === 'cn' ? '月' : d);
    const D = dateFns.doubleDigit(date.getDate()) + (d === 'cn' ? '日' : '');
    const h = `${dateFns.doubleDigit(date.getHours())}:`;
    const m = `${dateFns.doubleDigit(date.getMinutes())}:`;
    const s = dateFns.doubleDigit(date.getSeconds());
    return nohour ? Y + M + D : `${Y + M + D} ${h}${m}${s}`;
  } else {
    return '';
  }
};
export const dateFns = new dateFn();

export const dayNames = [
  '周日',
  '周一',
  '周二',
  '周二',
  '周四',
  '周五',
  '周六',
  '星期天',
  '星期一',
  '星期二',
  '星期三',
  '星期四',
  '星期五',
  '星期六',
];

export const isDate = function (date) {
  if (date === null || date === undefined) return false;
  if (isNaN(new Date(date).getTime())) return false;
  if (Array.isArray(date)) return false;
  return true;
};

/**
 * 格式化时间，检测了是否是有效的时间
 * @param {number} date
 * @param {string} format
 */
export const safeFormatDateStr = (date, format = 'YYYY.MM.DD') => {
  return isDate(date) ? dayjs(date).format(format) : '';
};

export {
  dateFormat,
  isTodayOrTomorrow,
  localeTimeFormat,
  secondToTime,
  toDate,
};
