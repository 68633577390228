interface IQrcodeItem {
  title: string;
  imgUrl: string;
  alt: string;
}

type TQrcodeList = IQrcodeItem[];

interface IHostMap {
  qrcodeTitle: string;
  qrcodeList: TQrcodeList;
}

type THost =
  | 'yunyucai.cn'
  | 'www.yunyucai.cn'
  | 'yyc.wlxiao.com'
  | 'wlxiao'
  | string;

const wlxiaoQrcodeList: TQrcodeList = [
  {
    title: '下载未来校App',
    imgUrl:
      'https://festatic.estudy.cn/assets/kkl-online-school/public/qrcode/wlxiao-app.png',
    alt: '未来校App二维码',
  },
  // {
  //   title: '关注未来校公众号',
  //   imgUrl:
  //     'https://festatic.estudy.cn/assets/kkl-online-school/public/qrcode/wlxiao-wechat.png',
  //   alt: '未来校公众号二维码',
  // },
];

const yycQrcodeList: TQrcodeList = [
  {
    title: '下载育才云朵朵App',
    imgUrl:
      'https://festatic.estudy.cn/assets/kkl-online-school/public/qrcode/yyc-app.png',
    alt: '育才云朵朵App二维码',
  },
  {
    title: '关注服务号',
    imgUrl:
      'https://festatic.estudy.cn/assets/kkl-online-school/public/qrcode/yyc-service.jpg',
    alt: '育才云朵朵服务号二维码',
  },
];

const wlxiaoQrcodeTitle = '下载App';
const yycQrcodeTitle = '下载App/关注公众号';

const yycMap: IHostMap = {
  qrcodeTitle: yycQrcodeTitle,
  qrcodeList: yycQrcodeList,
};

const wlxiaoMap: IHostMap = {
  qrcodeTitle: wlxiaoQrcodeTitle,
  qrcodeList: wlxiaoQrcodeList,
};

const hostAndQrcodeMap: {
  [k in THost]: IHostMap;
} = {
  'yunyucai.cn': yycMap,
  'www.yunyucai.cn': yycMap,
  'yyc.wlxiao.com': yycMap,
  /** 测试验证代码 */
  'm.xsit.kaikela.cn': yycMap,
  wlxiao: wlxiaoMap,
};

export const getHostData = () => {
  const { hostname } = window.location;
  let qrcodeTitle = '';
  let qrcodeList: TQrcodeList = [];

  if (hostAndQrcodeMap[hostname]) {
    const { qrcodeTitle: title, qrcodeList: list } = hostAndQrcodeMap[hostname];
    qrcodeTitle = title;
    qrcodeList = list;
  } else {
    qrcodeTitle = wlxiaoQrcodeTitle;
    qrcodeList = wlxiaoQrcodeList;
  }

  return {
    qrcodeTitle,
    qrcodeList,
  };
};
