import homeworkIcon from '@/assets/nav-icon/假期作业1@2x.png';
import homeworkActiveIcon from '@/assets/nav-icon/假期作业2@2x.png';
import { getIsYucai } from '@/common/scripts/utils/host';
const isYucai = getIsYucai();
const menuName = isYucai ? '假期育才' : '假期作业';

export default [
  {
    path: 'homeworkManage',
    name: 'homeworkManage',
    redirect: '/homeworkManage/homeworkList',
    component: () => import('@/views/homeworkManage/entry.vue'),
    meta: {
      role: 'teachingCenter.yucaiVacation',
      roleId: '11154',
      menuName,
      icon: homeworkIcon,
      activeIcon: homeworkActiveIcon,
    },
    children: [
      {
        path: 'homeworkList',
        name: 'homeworkList',
        redirect: '/homeworkManage/homeworkList/index',
        component: () =>
          import('@/views/homeworkManage/homeworkList/entry.vue'),
        meta: {
          role: 'teachingCenter.yucaiHomeworkList',
          roleId: '11220',
          menuName: '作业列表',
          icon: homeworkIcon,
          activeIcon: homeworkActiveIcon,
        },
        children: [
          {
            path: 'index',
            name: 'homeworkListIndex',
            component: () =>
              import('@/views/homeworkManage/homeworkList/index/entry.vue'),
            meta: {
              role: 'teachingCenter.yucaiHomeworkList',
              roleId: '11220',
              hideInMenu: true,
            },
          },
          {
            path: 'create',
            name: 'homeworkCreate',
            component: () =>
              import('@/views/homeworkManage/homeworkList/create/entry.vue'),
            meta: {
              role: 'teachingCenter.yucaiHomework.createPage',
              roleId: '11238',
              hideInMenu: true,
              hasSideMenu: false,
            },
          },
          {
            path: 'view',
            name: 'homeworkView',
            component: () =>
              import('@/views/homeworkManage/homeworkList/view/entry.vue'),
            meta: {
              role: 'teachingCenter.yucaiHomeworkList',
              roleId: '11220',
              hideInMenu: true,
              hasSideMenu: true,
            },
          },
        ],
      },
      {
        path: 'publishRecord',
        name: 'publishRecord',
        redirect: '/homeworkManage/publishRecord/list',
        component: () =>
          import('@/views/homeworkManage/publishRecord/list/entry.vue'),
        meta: {
          role: 'teachingCenter.yucaiHomeworkPublishRecord',
          roleId: '11227',
          menuName: '布置记录',
          icon: homeworkIcon,
          activeIcon: homeworkActiveIcon,
        },
        children: [
          {
            path: 'list',
            name: 'publishRecordList',
            component: () =>
              import('@/views/homeworkManage/publishRecord/list/entry.vue'),
            meta: {
              role: 'teachingCenter.yucaiHomeworkPublishRecord',
              roleId: '11227',
              hideInMenu: true,
            },
          },
        ],
      },
      {
        path: 'homeworkSchedule',
        name: 'homeworkSchedule',
        redirect: '/homeworkManage/homeworkSchedule/index',
        component: () =>
          import('@/views/homeworkManage/homeworkSchedule/entry.vue'),
        meta: {
          role: 'teachingCenter.yucaiHomeworkClassPlan',
          roleId: '11234',
          menuName: '班级假期计划',
          icon: homeworkIcon,
          activeIcon: homeworkActiveIcon,
        },
        children: [
          {
            path: 'index',
            name: 'homeworkScheduleIndex',
            component: () =>
              import('@/views/homeworkManage/homeworkSchedule/index/entry.vue'),
            meta: {
              role: 'teachingCenter.yucaiHomeworkClassPlan',
              roleId: '11234',
              hideInMenu: true,
            },
          },
          {
            path: 'exclusionSetting',
            name: 'homeworkExclusionSetting',
            component: () =>
              import(
                '@/views/homeworkManage/homeworkSchedule/exclusionSetting/entry.vue'
              ),
            meta: {
              role: '',
              roleId: '',
              hideInMenu: true,
            },
          },
          {
            path: 'answerVisibleSetting',
            name: 'homeworkAnswerVisibleSetting',
            component: () =>
              import(
                '@/views/homeworkManage/homeworkSchedule/answerVisibleSetting/entry.vue'
              ),
            meta: {
              role: '',
              roleId: '',
              hideInMenu: true,
            },
          },
        ],
      },

      {
        path: 'homeworkReview',
        name: 'homeworkReview',
        redirect: '/homeworkManage/homeworkReview/index',
        component: () =>
          import('@/views/homeworkManage/homeworkReview/entry.vue'),
        meta: {
          role: 'teachingCenter.yucaiHomeworkReview',
          roleId: '11169',
          menuName: '作业批阅',
          icon: homeworkIcon,
          activeIcon: homeworkActiveIcon,
        },
        children: [
          {
            path: 'index',
            name: 'homeworkReviewIndex',
            component: () =>
              import('@/views/homeworkManage/homeworkReview/index/entry.vue'),
            meta: {
              role: 'teachingCenter.yucaiHomeworkReview.list',
              roleId: '11170',
              hideInMenu: true,
            },
          },
          {
            path: 'homeworkSubmitDetail',
            name: 'homeworkSubmitDetail',
            component: () =>
              import(
                '@/views/homeworkManage/homeworkReview/homeworkSubmitDetail/entry.vue'
              ),
            meta: {
              role: '',
              roleId: '',
              hideInMenu: true,
            },
          },
          {
            path: 'modifyHomework',
            name: 'modifyHomework',
            component: () =>
              import(
                '@/views/homeworkManage/homeworkReview/modifyHomework/entry.vue'
              ),
            meta: {
              hideInMenu: true,
              hasSideMenu: false,
            },
          },
          {
            path: 'questionAnswerDetail',
            name: 'questionAnswerDetail',
            component: () =>
              import(
                '@/views/homeworkManage/homeworkReview/questionAnswerDetail/entry.vue'
              ),
            meta: {
              role: '',
              roleId: '',
              hideInMenu: true,
            },
          },
        ],
      },
      {
        path: 'advancedHomework',
        name: 'advancedHomework',
        redirect: '/homeworkManage/advancedHomework/list',
        component: () =>
          import('@/views/homeworkManage/advancedHomework/entry.vue'),
        meta: {
          role: 'teachingCenter.advanced',
          roleId: '',
          menuName: '培优作业',
          icon: homeworkIcon,
          activeIcon: homeworkActiveIcon,
          hasSubmenu: true,
        },
        children: [
          {
            path: 'list',
            name: 'advancedHomeworkList',
            redirect: '/homeworkManage/advancedHomework/list/index',
            component: () =>
              import('@/views/homeworkManage/advancedHomework/list/entry.vue'),
            meta: {
              role: 'teachingCenter.advanced.homework',
              roleId: '',
              menuName: '作业列表',
            },
            children: [
              {
                path: 'index',
                name: 'advancedHomeworkListIndex',
                component: () =>
                  import(
                    '@/views/homeworkManage/advancedHomework/list/index/entry.vue'
                  ),
                meta: {
                  role: 'teachingCenter.advanced.homework.list',
                  roleId: '',
                  hideInMenu: true,
                },
              },
              {
                path: 'detail',
                name: 'advancedHomeworkDetail',
                component: () =>
                  import(
                    '@/views/homeworkManage/advancedHomework/detail/entry.vue'
                  ),
                meta: {
                  role: 'teachingCenter.advanced.homework.list',
                  roleId: '',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: 'publishRecord',
            name: 'advancedHomeworkPublishRecord',
            redirect: '/homeworkManage/advancedHomework/publishRecord/list',
            component: () =>
              import(
                '@/views/homeworkManage/advancedHomework/publishRecord/entry.vue'
              ),
            meta: {
              role: 'teachingCenter.advanced.publish',
              roleId: '',
              menuName: '布置记录',
            },
            children: [
              {
                path: 'list',
                name: 'advancedHomeworkPublishRecordList',
                component: () =>
                  import(
                    '@/views/homeworkManage/advancedHomework/publishRecord/list/entry.vue'
                  ),
                meta: {
                  role: 'teachingCenter.advanced.publish.list',
                  roleId: '',
                  hideInMenu: true,
                },
              },
            ],
          },
        ],
      },
      {
        path: 'specialHomework',
        name: 'specialHomework',
        redirect: '/homeworkManage/specialHomework/list',
        component: () =>
          import('@/views/homeworkManage/specialHomework/entry.vue'),
        meta: {
          role: 'teachingCenter.feature',
          roleId: '11311',
          menuName: '特色作业',
          icon: homeworkIcon,
          activeIcon: homeworkActiveIcon,
        },

        children: [
          {
            path: 'list',
            name: 'specialHomeworkList',
            component: () =>
              import('@/views/homeworkManage/specialHomework/list/entry.vue'),
            meta: {
              role: 'teachingCenter.feature.homework',
              roleId: '11312',
              hideInMenu: true,
            },
          },
          {
            path: 'detail',
            name: 'specialHomeworkDetail',
            component: () =>
              import('@/views/homeworkManage/specialHomework/detail/entry.vue'),
            meta: {
              role: 'teachingCenter.feature.homework',
              roleId: '11312',
              hideInMenu: true,
            },
          },
        ],
      },
    ],
  },
];
