import { WLX, YYC } from '@/common/enums/appName';
import { getIsYucai } from '@/common/scripts/utils/host';
const app = getIsYucai() ? YYC : WLX;

// 校验心理档案查看短信验证码
export const checkMobileCodeByMentalArchive = (code: string) => {
  return KKL.kapi({
    serviceName: 'aischool.MemberCmdFacade.checkMobileCodeByMentalArchive',
    param: {
      cmd: { app, code },
    },
    noHint: true,
  });
};

// 发送验证心理档案查看短信验证码
export const sendMobileCodeByMentalArchive = () => {
  return KKL.kapi({
    serviceName: 'aischool.MemberCmdFacade.sendMobileCodeByMentalArchive',
    param: {
      cmd: { app },
    },
  });
};
